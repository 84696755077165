/* Header.css */
header {
    background-color: #f8f8f8;
    padding: 10px;
  }
  
  nav ul {
    display: flex;
    list-style-type: none;
  }
  
  nav ul li {
    margin-right: 10px;
  }
  
  nav ul li a {
    color: #333;
    text-decoration: none;
  }
  
  nav ul li a.active {
    color: #007bff;
  }
  
  .dropdown .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f8f8f8;
    padding: 10px;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown-content li {
    margin-bottom: 5px;
  }
  